import { StyleSheet, Text, useWindowDimensions, View } from 'react-native'
import React, { useEffect, useRef } from 'react'
import { Video, AVPlaybackStatus } from 'expo-av';
import ReactHlsPlayer from "react-hls-player";
import { LinearGradient } from 'expo-linear-gradient';
import { colors } from '../../colors';

const VideoBackground = ({videoUrl, muted = true, controls = false}) => {
  const window = useWindowDimensions();
  const video = useRef();

  useEffect(()=>{
    if(video) {
        setTimeout(()=>video?.current?.play(), 2000);
    }
  },[])

  return (
    <View style={[styles.container, {width: window.width, height: window.height}]}>
      <ReactHlsPlayer
        src={`/assets/trailers/${videoUrl}/hls/${videoUrl}.m3u8`}
        autoPlay={true}
        controls={controls}
        width="100%"
        height="auto"
        muted={muted}
        playerRef={video}
        style={{ height: window.height, width: window.width}}
      />
     {!controls && <><LinearGradient
        // Background Linear Gradient
        colors={[colors.background, colors.background, 'transparent']}
        style={[styles.background, { height: window.height / 3 }]} /><LinearGradient
          // Background Linear Gradient
          colors={['transparent', colors.background, colors.background]}
          style={[styles.backgroundBot, { height: window.height / 3 }]} /></>}
      {/* <Video
        ref={video}
        style={[styles.video]}
        videoStyle={{width: '100%', height: '100%'}}
        // usePoster
        // posterStyle={{width: '100%', height: '100%', resizeMode: 'cover'}}
        // posterSource={{uri: `/assets/images/${posterUrl}`}}
        source={{
          uri : 'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
        }}
        shouldPlay={true}
        // onPlaybackStatusUpdate={status => setStatus(() => status)}
        // onReadyForDisplay={updateVideoRatioOnDisplay}
      /> */}
    </View>
  )
}

export default VideoBackground

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'absolute',
        backgroundColor: colors.background
    },
    video: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    background: {
        width: '100%',
        position: 'absolute',
        top: 0,
    },
    backgroundBot: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
    },
})