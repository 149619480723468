import { StyleSheet, Image, View } from 'react-native'
import React from 'react'

const Header = () => {
  return (
    <View style={styles.container}>
      <Image resizeMode="contain" style={styles.logo} source={{uri: '/assets/images/ivyooo.png'}} />
    </View>
  )
}

export default Header

const styles = StyleSheet.create({
    container: {
        width: '100%',
        height: 90,
        top: 40,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
    },
    logo: {
      width: 90,
      height: 90,
    }
})