export const menus = [
    {text: 'HOME'},
    {text: 'DOWNLOADS'},
    {text: 'ABOUT US'},
];

// Action - 24 Hours to Live,
// Comedy - Ideal Home,
// Family - Ainbo,
// Suspense - Beyond Skyline,
// Fantasy- Abigail,
// Drama- After the Wedding,
// Horror - The Sadness (New Movie),
// Musical - Music of Silence

export const movies = [
    {
        name: 'Dance to Death',
        genre: 'Action',
        videoUrl: 'e6a20cda-e1ff-4476-83a0-a34ed4d2a782',
        posterUrl: 'e6a20cda-e1ff-4476-83a0-a34ed4d2a782.jpg',
        titleCardUrl: 'e6a20cda-e1ff-4476-83a0-a34ed4d2a782.png',
    },
    {
        name: 'Superbob',
        genre: 'Comedy',
        videoUrl: 'af047535-3581-4670-b7cb-a04423071bd3',
        posterUrl: 'af047535-3581-4670-b7cb-a04423071bd3.jpg',
        titleCardUrl: 'af047535-3581-4670-b7cb-a04423071bd3.png',
    },
    {
        name: 'Ainbo',
        genre: 'Family',
        videoUrl: '85f53cab-38f0-4fcb-b76d-d938735f3df7',
        posterUrl: '85f53cab-38f0-4fcb-b76d-d938735f3df7.jpg',
        titleCardUrl: '85f53cab-38f0-4fcb-b76d-d938735f3df7.png',
    },
    {
        name: 'BTS',
        genre: 'Biography',
        videoUrl: '8ab99aee-c715-48b5-bb42-58141ff6556a',
        posterUrl: '8ab99aee-c715-48b5-bb42-58141ff6556a.jpg',
        titleCardUrl: '8ab99aee-c715-48b5-bb42-58141ff6556a.png',
    },
    {
        name: 'Abigail',
        genre: 'Fantasy',
        videoUrl: '3195b662-b9bc-4321-bd42-c8e9ae5b5f34',
        posterUrl: '3195b662-b9bc-4321-bd42-c8e9ae5b5f34.jpg',
        titleCardUrl: '3195b662-b9bc-4321-bd42-c8e9ae5b5f34.png',
    },
    {
        name: 'Backstabbing for Beginners',
        genre: 'Drama',
        videoUrl: 'c1183adc-b66a-40b0-a662-51403ad32218',
        posterUrl: 'c1183adc-b66a-40b0-a662-51403ad32218.jpg',
        titleCardUrl: 'c1183adc-b66a-40b0-a662-51403ad32218.png',
    },
    {
        name: 'Doorlock',
        genre: 'Suspense',
        videoUrl: '9ae654bf-dfdd-4b99-a8a1-95841a24e217',
        posterUrl: '9ae654bf-dfdd-4b99-a8a1-95841a24e217.jpg',
        titleCardUrl: '9ae654bf-dfdd-4b99-a8a1-95841a24e217.png',
    },
    {
        name: 'Music of Silence',
        genre: 'Musical',
        videoUrl: 'bc3f52f1-f642-4713-8a0f-6388f8b06d8b',
        posterUrl: 'bc3f52f1-f642-4713-8a0f-6388f8b06d8b.jpg',
        titleCardUrl: 'bc3f52f1-f642-4713-8a0f-6388f8b06d8b.png',
    },
    ]