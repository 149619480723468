import { Image, Pressable, useWindowDimensions, Dimensions, StyleSheet, Text, View } from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import Ionicons from '@expo/vector-icons/Ionicons';
import Animated, { Transition, withTiming, withDelay, Easing, SlideInUp ,FadeInDown, FadeOut, useAnimatedStyle, withSpring, useSharedValue } from 'react-native-reanimated';
import { LinearGradient } from 'expo-linear-gradient';
import { colors } from '../../colors';
import { Video, AVPlaybackStatus } from 'expo-av';
import {screen} from '../../utils'

const ReanimatedPressable = Animated.createAnimatedComponent(Pressable);
const defaultScreenRatio = Dimensions.get("window").width / Dimensions.get("window").height;

const Cards = ({isTop, index, genre, url, name, posterUrl, titleCardUrl, setVideoUrl, setIsVisible}) => {
  const window = useWindowDimensions();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFill, setIsFill] = useState(false);
  const [status, setStatus] = useState({});
  const flexValue = useSharedValue(1);
  const opacityVal = useSharedValue(0);
  const videoWidth = useSharedValue(0);
  const videoHeight = useSharedValue(0);
  const buttonOpacity = useSharedValue(0);
  const video = useRef(null);
  const opacityValue = useSharedValue(0.4);

  // Use the screenDefaultRatio to render the video before the video is loaded
    const [videoRatio, setVideoRatio] = useState(defaultScreenRatio);

    // Update the videoRatio right after we know the video natural size
    const updateVideoRatioOnDisplay = (videoDetails) => {
        const { width, height } = videoDetails.naturalSize;
        const newVideoRatio = width / height;

        setVideoRatio(newVideoRatio);
    }

  const animatedStyles = useAnimatedStyle(() => {
    return {
      flex: flexValue.value,
      opacity: opacityVal.value,
    //   width: videoWidth.value,
    //   height: videoHeight.value,
    };
  });

  const animatedButtonStyles = useAnimatedStyle(() => {
    return {
      opacity: buttonOpacity.value,
    //   width: videoWidth.value,
    //   height: videoHeight.value,
    };
  });

//   const animatedImageStyles = useAnimatedStyle(() => {
//     return {
//       width: posterWidth.value,
//       height: posterHeight.value,
//     };
//   });

  const animatedMaskStyles = useAnimatedStyle(() => {
    return {
      opacity: opacityValue.value
    };
  });

  const expandCard = () => {
    setIsExpanded(true);
    setVideoUrl(url);
    flexValue.value = withTiming(4, {duration: 400, easing: Easing.linear});
    buttonOpacity.value = withDelay(500, withTiming(1));
    opacityValue.value = 0;
  }

  const collapseCard = () => {
    setIsExpanded(false);
    // setStatus({});
    flexValue.value = withSpring(1);
    buttonOpacity.value = 0;
    opacityValue.value = 0.4;

  }

  const pressCard = () => {
    setIsFill(true);
    setIsVisible(true);
    // videoWidth.value = withSpring(window.width);
    // videoHeight.value = withSpring(window.height);
    // posterHeight.value = withTiming(window.height);
    // posterWidth.value = withTiming(window.width);
  }

  useEffect(()=>{
    opacityVal.value = withDelay(300 * index, withTiming(1, {duration: 1000,easing: Easing.linear}));
  }, []);


  return (
    <ReanimatedPressable key={`${genre}-${name}`} onPress={pressCard} onHoverIn={expandCard} onHoverOut={collapseCard} style={[styles.container, {top: isTop ? -40 : 40}, animatedStyles]}>
      {!!posterUrl && <Animated.Image resizeMode='cover' style={styles.image} source={{uri: `/assets/images/${posterUrl}`}} />}
      {/* <Animated.View style={[styles.mask, animatedMaskStyles]}></Animated.View> */}
      {!isExpanded && <Animated.Text style={styles.genre}>{genre}</Animated.Text>}
      {/* {isExpanded && <Video
        ref={video}
        // usePoster
        // posterStyle={{width: '100%', height: '100%', resizeMode: 'cover'}}
        // posterSource={{uri: `/assets/images/${posterUrl}`}}
        style={[styles.video, {aspectRatio: defaultScreenRatio}]}
        source={{
          uri: url ? `/assets/trailers/Ainbo_TLR_240p.mp4` : 'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
        }}
        shouldPlay={isExpanded}
        onPlaybackStatusUpdate={status => setStatus(() => status)}
        // onReadyForDisplay={updateVideoRatioOnDisplay}
      />} */}
      {<Animated.Image resizeMode="contain" style={[styles.titleCard, animatedButtonStyles]} source={{uri: `/assets/images/${titleCardUrl}`}} />}

      <LinearGradient
        // Background Linear Gradient
        colors={[colors.background, colors.background, 'transparent']}
        style={styles.background}
      />
      <LinearGradient
        // Background Linear Gradient
        colors={['transparent', colors.background,colors.background]}
        style={styles.backgroundBot}
      />
      {<Animated.View style={[styles.trailer, animatedButtonStyles]}>
        <Ionicons name="play-circle-outline" size={28} color={colors.white} />
        <Text style={styles.trailerText}>WATCH TRAILER</Text>
      </Animated.View>}
    </ReanimatedPressable>
  )
}

export default Cards

const styles = StyleSheet.create({
    container: {
        flex: 1,
        margin: 10,
        height: '60%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        overflow: 'hidden',
    },
    image: {
        flex: 1,
        width: '100%',
        height: 500,
    },
    mask: {
        backgroundColor: 'black',
        opacity: 0.4,
        ...StyleSheet.absoluteFill,
    },
    genre: {
        color: colors.white,
        position: 'absolute',
        alignSelf: 'center',
        fontWeight: '600',
        fontSize: 18,
        padding: 4,
    },
    background: {
        width: '100%',
        height: 30,
        position: 'absolute',
        top: 0,
    },
    backgroundBot: {
        width: '100%',
        height: 30,
        position: 'absolute',
        bottom: 0,
    },
    video: {
        alignSelf: 'center',
        width: window.width,
        height: window.height,
        aspectRatio: window.width / window.height,
        left: 0,
        right: 0,
        backgroundColor: 'yellow'
    },
    titleCard: {
        height: 200,
        width: 300,
        alignSelf: 'center',
        position: 'absolute'
    },
    trailer: {
      padding: 8,
      paddingHorizontal: 20,
      backgroundColor: colors.red,
      borderRadius: 40,
      position: 'absolute',
      bottom: '10%',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    trailerText: {
      color: colors.white,
      paddingLeft: 6,
      fontWeight: '600'
    }
})