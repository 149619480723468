import { StyleSheet, Text, Linking, View, ScrollView } from 'react-native'
import React, { useEffect } from 'react'
import DownloadsQR from '../../components/Downloads';
import { isMobile } from 'react-device-detect';
import { colors } from '../../colors';
import * as Analytics from 'expo-firebase-analytics';
import { useRoute } from '@react-navigation/native';

const QRCode = () => {
  const route = useRoute();

  useEffect(()=>{
    Analytics.logEvent('from_qrcode', {
      platform: isMobile ? 'mobile' : 'desktop',
      code: route?.params?.code
    });
    // setTimeout(()=> {
    //   Linking.openURL('/qrcode/smcinemas')
    // }, 500);
  });

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.content}>
      <DownloadsQR />
    </ScrollView>
  )
}

export default QRCode

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    overflow: 'hidden'
  },
  content: {
    height: '100%',
  }
})