import _ from 'lodash'
import { StyleSheet, Text, View, Modal, Button, Platform, TouchableOpacity, Image, SafeAreaView } from 'react-native'
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react'
import Ionicons from '@expo/vector-icons/Ionicons';
import { colors } from '../../colors'
import Header from '../../components/Header'
import Cards from '../../components/Cards'
import Menu from '../../components/Menu'
import {menus, movies} from '../../data'
import useSelectedRoutes from '../../hooks/useSelectedRoutes'
import Downloads from '../../components/Downloads'
import VideoBackground from '../../components/VideoBackground'
import { Video } from 'expo-av'
import Footer from '../../components/Footer'
import AboutUS from '../../components/AboutUS';

const HomeScreen = () => {
  const {selectedRoute, setSelectedRoute} = useSelectedRoutes();
  const [ videoUrl, setVideoUrl ] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  if(isMobile){
    return (
    <SafeAreaView style={styles.container}>
      <Downloads />
    </SafeAreaView>)
  } else {
    return (
      <View style={styles.container}>
        <VideoBackground videoUrl={videoUrl} />
        <View style={styles.backdrop}></View>
        <Header />
        <View style={styles.body}>
          <Modal
            animationType={'slide'}
            onRequestClose={() => setIsVisible(false)}
            visible={isVisible}
          >
            <View style={styles.container}>
              <VideoBackground muted={false} controls={true} videoUrl={videoUrl} />
              <TouchableOpacity onPress={() => setIsVisible(false)} style={styles.backButton}>
                <Ionicons name="arrow-back-circle-outline" size={70} color={colors.white} />
              </TouchableOpacity>
              <Image resizeMode='contain' style={styles.titleCard} source={{uri: `/assets/images/${videoUrl}.png`}} />
            </View>
          </Modal>
          {selectedRoute === 'HOME' && <View style={styles.movies}>
            {_.map(movies, (item, index)=> <Cards setIsVisible={setIsVisible} setVideoUrl={setVideoUrl} isTop={index % 2 == 0} index={index} genre={item?.genre} posterUrl={item?.posterUrl} name={item?.name} titleCardUrl={item?.titleCardUrl} url={item?.videoUrl} />)}
          </View>}
          {selectedRoute === 'DOWNLOADS' && <Downloads />}
          {selectedRoute === 'ABOUT US' && <AboutUS />}
          <View style={styles.sidebar}>
            {_.map(menus, (item, index) => <Menu key={`${item?.text}-${index}`} selectedRoute={selectedRoute} setSelectedRoute={setSelectedRoute} text={item?.text} />)}
          </View>
        </View>
        <Footer />
      </View>
    )
  }
}

export default HomeScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.background,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    backButton: {
      width: 50,
      height: 50,
      position: 'absolute',
      top: 40,
      justifyContent: 'center',
      alignItems: 'center',
      left: 40,
    },
    backdrop: {
      flex: 1,
      backgroundColor: colors.background,
      opacity: 0.8,
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    body: {
      flex: 1,
      flexDirection: 'row',
    },
    sidebar: {
      width: 300,
      position: 'absolute',
      alignSelf: 'center',
      left: 0,
      // width: 400,
      height: '40%',
      // backgroundColor: 'green',
      // borderWidth: 2,
      // borderColor: 'green',
    },
    movies: {
      flex: 8,
      padding: 40,
      marginLeft: 200,
      // backgroundColor: 'yellow',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    titleCard: {
      position: 'absolute',
      width: '20%',
      height: 80,
      margin: 20,
      alignSelf: 'center',
    }
})