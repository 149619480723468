import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import HomeScreen from './src/screens/Home';
import QRCode from './src/screens/QRCode';
import Downloads from './src/components/Downloads';

const Stack = createNativeStackNavigator();

const linking = {
  prefixes: ['https://ivyooo.com'],
  config: {
    screens: {
      Home: '',
      qrcode: 'qrcode/:code'
    }
  },
};

export default function App() {
  return (
      <NavigationContainer linking={linking}>
        <Stack.Navigator screenOptions={{headerShown: false}}>
          <Stack.Screen name="Home" component={HomeScreen} />
          <Stack.Screen name="qrcode" component={Downloads} />
        </Stack.Navigator>
      </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
});
