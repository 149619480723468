import { StyleSheet, Linking, Text, useWindowDimensions, Image, View, TouchableOpacity, Alert } from 'react-native'
import React, { useEffect, useState } from 'react'
import Animated, {useAnimatedStyle, withSpring, useSharedValue, color} from 'react-native-reanimated'
import { colors } from '../../colors';
import Entypo from '@expo/vector-icons/Entypo';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';
import Ionicons from '@expo/vector-icons/Ionicons';
import { isIOS, isMobile, isSafari } from 'react-device-detect';
import Footer from '../Footer';
import { useRoute } from '@react-navigation/native';


const Downloads = () => {
    const window = useWindowDimensions();
    const route = useRoute();
    const [showBackdrop, setShowBackdrop] = useState(true);
    const rightPost = useSharedValue(-window.width);
    const bottomPos = useSharedValue(-220)
    const animatedStyles = useAnimatedStyle(() => {
        return {
            right: rightPost.value,
        };
    });

    console.log(route);

    const bottomAnimatedStyles = useAnimatedStyle(() => {
      return {
        bottom: bottomPos.value
      };
    });

    // const openAppStore = () => Linking.openURL('https://apps.apple.com/us/app/ivyooo/id1615391234');
    const openAppStore = () => {
      if(route.path === '/'){
        console.log('NO QR')
        Linking.openURL('https://ivyooo.page.link/DSF9');
      } else {
        console.log('WITH QR');
        Linking.openURL('https://ivyooo.page.link/iho8');
      }
    };

    const openPlayStore = () => Linking.openURL('https://play.google.com/store/apps/details?id=com.crystalsky.ivyooo');
    const openSMTickets = () => Linking.openURL('https://smtickets.com/events/view/11376');
    const openTicketWorld = () => Linking.openURL('https://premier.ticketworld.com.ph/shows/show.aspx?sh=CNMLYA22');
    

    const hoverInMenu = () => rightPost.value = withSpring(window.width * 0.8);

    const mountModal = () => bottomPos.value = withSpring(-60, {stiffness: 50});

    const hideModal = () => {
      setShowBackdrop(false);
      bottomPos.value = withSpring(-window.height, {stiffness: 50})
    };

    const hoverOutMenu = () => rightPost.value = withSpring(-window.width);

    useEffect(() => {
        hoverInMenu();
        mountModal();
    }, []);

    useEffect(() => {
        hoverInMenu();
    }, [window.width]);
    

  return (
    <View style={styles.container}>
      {!isMobile && <Animated.View style={[styles.design, animatedStyles]}>
            <View style={[styles.triangle, {height: window.height * 2}]}></View>
        </Animated.View>}
        <View style={[styles.bodyContainer, {flexDirection: window.width > 768 ? 'row' : 'column'}]}>
            <View style={styles.downloadContainer}>
            <Image resizeMode="contain" style={styles.logo} source={{uri: '/assets/images/phone.png'}} />
            <Text style={{color: colors.red, fontWeight: 'bold', fontSize: 48, padding: 0}}>iVYOOO</Text> 
            <Text style={styles.download}>is OUT NOW!</Text>
            {!isMobile && <Text style={[styles.avail, {fontStyle: 'italic', fontSize: 16, paddingTop: 0}]}>COMING SOON on your FAVORITE BROWSER</Text>}
            <View style={{flexDirection: window.width > 768 ? 'row' : 'column'}}>
                <TouchableOpacity onPress={openPlayStore} style={styles.downloadBtn}>
                <Entypo name="google-play" size={32} color={colors.red} />
                <View style={styles.availContainer}>
                    <Text style={styles.avail}>GET IT ON</Text>
                    <Text style={[styles.avail, {fontSize: 18, fontWeight: '600', color: 'white'}]}>GOOGLE PLAY</Text>
                </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={openAppStore} style={styles.downloadBtn}>
                <FontAwesome5 name="app-store-ios" size={32} color={colors.red} />
                <View style={styles.availContainer}>
                    <Text style={styles.avail}>Download on the</Text>
                    <Text style={[styles.avail, {fontSize: 18, fontWeight: '600', color: 'white'}]}>APP STORE</Text>
                </View>
                </TouchableOpacity>
            </View>
            </View>
        </View>
        {isMobile && showBackdrop && <Animated.View  style={styles.backdrop} />}
        {isMobile && <Animated.View style={[styles.bottomModal, bottomAnimatedStyles]}>
          <Text style={styles.text}>See iVYOOO in...</Text>
          <View style={{height: 50, width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Image style={styles.icons} source={{uri: '/assets/images/ivyooo.png'}} />
              <Text style={styles.appText}>iVYOOO App</Text>
            </View>
            <TouchableOpacity onPress={openAppStore} style={styles.openButton}>
              <Text style={{color: colors.text, fontWeight: '600', textAlign: 'center'}}>Open</Text>
            </TouchableOpacity>
          </View>
          <View style={{height: 50, width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <Ionicons style={[styles.icons, {textAlign: 'center'}]} name="globe-outline" size={30} color={colors.grey} />
              <Text style={styles.appText}>{isIOS ? 'Safari' : 'Browser'}</Text>
            </View>
            <TouchableOpacity onPress={hideModal} style={styles.continueButton}>
              <Text style={{color: colors.offwhite, fontWeight: '600', textAlign: 'center'}}>Continue</Text>
            </TouchableOpacity>
          </View>
        </Animated.View>}
    </View>
  )
}

export default Downloads

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.background,
        height: '100%',
    },
    bottomModal: {
      position: 'absolute',
      height: 220,
      width: '100%',
      bottom: 20,
      backgroundColor: 'white',
      overflow: 'hidden',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },  
    design: {
        flex: 1,
        backgroundColor: colors.red,
    },
    triangle: {
        backgroundColor: colors.red,
        flex: 1,
        transform: [
            {
                rotate: '45deg',
                translateY: -'50%',
            }
        ]
    },
    avail: {
        color: '#A1A1A1'
      },
      button: {
        backgroundColor: colors.red,
        borderRadius: 40,
        width: '80%',
        alignSelf: 'center',
        margin: 10,
      },
      video: {
        width: '100%',
        height: '100%',
        position: 'absolute',
      },
      downloadContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
      trailerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
      phone: {
        height: '150%',
        width: '150%',
        right: '-50%',
        position: 'absolute',
        top: -100,
      },
      backdrop: {
        position: 'absolute',
        flex: 1,
        backgroundColor: 'black',
        opacity: 0.8,
        width: '100%',
        height: '100%',
      },
      availContainer: {
        flexDirection: 'column',
        padding: 4,
        marginLeft: 4,
        paddingHorizontal: 10,
      },
      bodyContainer: {
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute'
      },
      downloadBtn: {
        borderWidth: 1,
        borderColor: colors.red,
        backgroundColor: 'black',
        borderRadius: 4,
        padding: 8,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 20,
      },  
      download: {
        color: 'white',
        textAlign: 'center',
        padding: 20,
        fontSize: 28,
      },
      logo: {
        width: 500,
        height: 400
      },
      header: {
        width: '100%',
        height: 100,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingHorizontal: 20,
      },
      version: {
        color: colors.text,
        bottom: 0,
        width: '100%',
        backgroundColor: 'red',
        textAlign: 'center'
      },
      text : {textAlign: 'center', padding: 10, fontWeight: 'bold', marginBottom: 10, borderBottomWidth: 0.1, borderBottomColor: 'lightgrey'},
      appText : {textAlign: 'center', fontWeight: '600'},
      icons: {
        height: 40,
        width: 40,
        marginHorizontal: 10,
        borderWidth: 0.1,
        borderColor: 'lightgrey',
        borderRadius: 4,
      },
      openButton: {
        backgroundColor: colors.red,
        padding: 10,
        paddingHorizontal: 20,
        borderRadius: 40,
        marginRight: 10,
        marginRight: 10,
        width: 100,
      },
      continueButton: {
        backgroundColor: colors.lightgrey,
        borderWidth: 0.1,
        borderColor: colors.offwhite,
        padding: 10,
        // paddingHorizontal: 10,
        borderRadius: 40,
        marginRight: 10,
        width: 100,
      }
})