import { StyleSheet, Text, View } from 'react-native'
import React, { useEffect } from 'react'
import { colors } from '../../colors'
import Animated, { useAnimatedStyle, useSharedValue, withDelay, withSpring, withTiming } from 'react-native-reanimated';

const AboutUS = () => {

  const widthVal = useSharedValue(0);
  const opacityVal1 = useSharedValue(0);
  const opacityVal2 = useSharedValue(0);
  const opacityVal3 = useSharedValue(0);

  const backAnimatedStyle = useAnimatedStyle(() => {
    return {
        width: widthVal.value,
    }
  });

  const animatedCard1 = useAnimatedStyle(() => {
    return {
        opacity: opacityVal1.value,
    }
  });

  const animatedCard2 = useAnimatedStyle(() => {
    return {
        opacity: opacityVal2.value,
    }
  });

  const animatedCard3 = useAnimatedStyle(() => {
    return {
        opacity: opacityVal3.value,
    }
  });

  useEffect(() => {
    widthVal.value = withSpring(400)
    opacityVal1.value = withDelay(400, withSpring(1))
    opacityVal2.value = withDelay(800, withSpring(1))
    opacityVal3.value = withDelay(1200, withSpring(1))
}, [])

  return (
    <View style={styles.container}>
        <Animated.View style={[styles.backDesign, backAnimatedStyle]}/>
        <Animated.View style={[styles.cardContainer, animatedCard2]}>
            <View style={styles.cardTitle}>
                <Text style={styles.cardTitleText}>OUR VISION</Text>
            </View>
            <View style={styles.descContainer}>
                <Text style={styles.desc}>• To put iVYOOO in the world map of digital entertainment and technology as wholly owned Filipino streaming app</Text>
                <Text style={styles.desc}>• To position iVYOOO as a globally competitive streaming app for high quality entertainment and educational content catering to local and international markets</Text>
            </View>
        </Animated.View>
        <Animated.View style={[styles.cardContainer, animatedCard1]}>
            <View style={styles.cardTitle}>
                <Text style={styles.cardTitleText}>WHO WE ARE</Text>
            </View>
            <View style={styles.descContainer}>
                <Text style={styles.desc}>• A wholly Filipino owned Over-the-Top (OTT) platform that is accessible anytime in the Philippines and Coming Soon on international territories.</Text>
                <Text style={styles.desc}>• Subscription-based streaming app that features a wide selection of digital entertainment and e-learning content</Text>
                <Text style={styles.desc}>• Distributor of internationally renowned films</Text>
                <Text style={styles.desc}>• Provider of affordable and high-quality Video-on-Demand content such as movies, series, concerts, live events, sports and a lot more</Text>
                <Text style={styles.desc}>• Producer of educational content that is available both online and offline nationwide.</Text>
                <Text style={styles.desc}>• Innovator of technological features for ultimate interactive digital viewing experience</Text>
            </View>
        </Animated.View>
        <Animated.View style={[styles.cardContainer, animatedCard3]}>
            <View style={styles.cardTitle}>
                <Text style={styles.cardTitleText}>OUR MISSION</Text>
            </View>
            <View style={styles.descContainer}>
                <Text style={styles.desc}>• To serve as a streaming platform that is accessible and affordable to every Filipino here and abroad.</Text>
                <Text style={styles.desc}>• To promote and showcase high quality movies, series, concerts, live events, sports, educational programs and more for interactive entertainment and learning.</Text>
                <Text style={styles.desc}>• To provide early exposure to e-learning among school children through watch-and-learn formatted educational content for outcome-based learning.</Text>
                <Text style={styles.desc}>• To uplift the Philippine film makers/content creators by promoting iVYOOO as a locally developed streaming app of global standards with worldwide acceptance and patronage.</Text>
            </View>
        </Animated.View>
    </View>
  )
}

export default AboutUS

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        marginLeft: 300,
    },
    cardTitleText: {
        color: colors.white,
        fontWeight: '600',
        fontSize: 24
    },
    cardContainer: {
        width: '30%',
        margin: 20,
    },
    desc: {
        color: '#CCC',
        padding: 6,
        lineHeight: 18,
    },
    cardTitle: {
        backgroundColor: colors.red,
        padding: 20,
        paddingVertical: 14,
    },
    descContainer: {
        padding: 20,
        backgroundColor: colors.background
    },
    backDesign: {
        backgroundColor: colors.offwhite,
        position: 'absolute',
        width: '40%',
        height: '200%',
        transform : [
            { rotate: '30deg' },
        ]
    }
})