import { StyleSheet, Text, TouchableOpacity, Linking, View } from 'react-native'
import React from 'react'
import Ionicons from '@expo/vector-icons/Ionicons';
import { colors } from '../../colors'

const Footer = () => {
  const openAppStore = () => Linking.openURL('https://www.facebook.com/ivyoootv');
  const openTwitter = () => Linking.openURL('https://www.twitter.com/ivyoootv')
  const openInstagram = () => Linking.openURL('https://www.instagram.com/ivyoootv')
  return (
    <View style={styles.container}>
      <Text style={styles.text}>Copyright 2022 ivyooo.com | IVYOOO, INC. | Privacy Policy</Text>
      <View style={styles.social}>
        <Text style={styles.textSocial}>Follow us on </Text>
        <View style={styles.socialIcons}>
          <TouchableOpacity onPress={openAppStore} style={styles.button}>
              <Ionicons name="logo-facebook" size={28} color={colors.offwhite} />
          </TouchableOpacity>
          <TouchableOpacity onPress={openTwitter} style={styles.button}>
              <Ionicons name="logo-twitter" size={28} color={colors.offwhite} />
          </TouchableOpacity>
          <TouchableOpacity onPress={openInstagram} style={[styles.button, {marginRight: 40}]}>
              <Ionicons name="logo-instagram" size={28} color={colors.offwhite} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

export default Footer

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: 60,
        position: 'absolute',
        bottom: 0,
        backgroundColor: 'black',
        paddingLeft: 40,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    socialIcons: {
      justifyContent: 'center',
      flexDirection: 'row',
    },
    text: {
        color: colors.offwhite,
    },
    button: {
        width: 40,
        height: 40,
        // marginRight: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    social: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textSocial: {
        color: colors.offwhite,
        paddingRight: 6,
    }
})