import { StyleSheet, Text, Pressable } from 'react-native'
import React, { useEffect } from 'react'
import Animated, {FlipInYLeft, useAnimatedStyle, useSharedValue, withSpring} from 'react-native-reanimated'
import useSelectedRoutes from '../../hooks/useSelectedRoutes';
import { colors } from '../../colors';

const ReanimatedPressable = Animated.createAnimatedComponent(Pressable);

const Menu = ({text, setSelectedRoute, selectedRoute, ...props}) => {
  const leftPos = useSharedValue(0);
  // const perspectPos = useSharedValue(0);
  const setRoute = () =>  {
    setSelectedRoute(text)
    // perspectPos.value = '-' + withSpring(40) + 'deg'
  };

  const animatedStyles = useAnimatedStyle(() => {
    return {
      left: leftPos.value,
      // transform: [
      //     { perspective: 850 },
      //     { rotateY: perspectPos.value },
      // ],
    };
  });

  const hoverInMenu = () => leftPos.value = withSpring(20);

  const hoverOutMenu = () => leftPos.value = withSpring(0);

  // useEffect(()=>{
  //   // perspectPos.value = '-' + withSpring(40) + 'deg'
  // }, [])

  return (
    <ReanimatedPressable props onPress={setRoute} onHoverIn={hoverInMenu} onHoverOut={hoverOutMenu} style={[styles.container, animatedStyles]}>
      <Text style={[styles.text, {fontSize: selectedRoute === text ? 28 : 20, fontWeight: selectedRoute === text ? 'bold' : '600', color: selectedRoute === text ? (selectedRoute === 'DOWNLOADS' ? colors.white : colors.red): '#CCC'}]}>{text}</Text>
    </ReanimatedPressable>
  )
}

export default Menu

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        justifyContent: 'center',
        // alignItems: 'center',
        margin: 20,
        marginLeft: 40,
        transform: [
          { perspective: 850 },
          { rotateY: '0deg'},
        ],
    },
    text: {
        color: colors.text,
        fontWeight: '600',
        fontSize: 24,
        letterSpacing: 0.5,
    }
})